import { addHours, isAfter, isBefore, subMinutes } from "date-fns";
import { ProviderLimitsProps } from "../models/companyProps";

function isAppointmentOnTime(appointmentDate: Date) {
  const now = new Date();
  const isOnTime =
    isAfter(now, subMinutes(appointmentDate, 5)) &&
    isBefore(now, addHours(appointmentDate, 1));

  return isOnTime;
}

function getMonthlyInstantAppointmentsLimit(
  selectedProvider: ProviderLimitsProps,
  specialtyId: number
) {
  const specialtyExists =
    !!selectedProvider.specialties &&
    !!selectedProvider.specialties[specialtyId];

  const instantAppointmentsLimit = {
    generalLimit: selectedProvider.monthlyInstantAppointmentsLimit,
    specialtyLimit: selectedProvider.monthlyInstantAppointmentsLimit,
  };

  if (specialtyExists && specialtyId) {
    instantAppointmentsLimit.specialtyLimit =
      selectedProvider.specialties[specialtyId].monthlyInstantAppointmentsLimit;
  }

  return instantAppointmentsLimit;
}
function getMonthlyAppointmentsLimit(
  selectedProvider: ProviderLimitsProps,
  specialtyId: number
) {
  const specialtyExists =
    !!selectedProvider.specialties &&
    !!selectedProvider.specialties[specialtyId];

  const appointmentsLimit = {
    generalLimit: selectedProvider.monthlyAppointmentsLimit,
    specialtyLimit: selectedProvider.monthlyAppointmentsLimit,
  };

  if (specialtyExists && specialtyId) {
    appointmentsLimit.specialtyLimit =
      selectedProvider.specialties[specialtyId].monthlyAppointmentsLimit;
  }

  return appointmentsLimit;
}

export {
  isAppointmentOnTime,
  getMonthlyInstantAppointmentsLimit,
  getMonthlyAppointmentsLimit,
};
