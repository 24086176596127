import { collection, getDocs, query, where } from "firebase/firestore";
import { APPOINTMENT_STATUS } from "../../../utils/constants";
import { firestore } from "../../../config/firebase";
import { setCycleDaysRange } from "../../../utils/setCycleDaysRange";

interface GetRequestedAppointmentsInLastsDaysProps {
  userId: string;
  cycleDay: number;
  isInstantAppointment: boolean;
}

async function getRequestedAppointmentsInLastsDays(
  props: GetRequestedAppointmentsInLastsDaysProps
) {
  const { userId, cycleDay, isInstantAppointment } = props;

  try {
    const { startingDate, endingDate } = setCycleDaysRange(cycleDay);

    const collectionRef = collection(firestore, "appointments");

    const noShowAppointmentsQuery = query(
      collectionRef,
      where("patientId", "==", userId),
      where("isInstantAppointment", "==", isInstantAppointment),
      where("createdAt", ">=", startingDate),
      where("createdAt", "<", endingDate),
      where("status", "==", APPOINTMENT_STATUS.NO_SHOW)
    );
    const openAppointmentsQuery = query(
      collectionRef,
      where("patientId", "==", userId),
      where("isInstantAppointment", "==", isInstantAppointment),
      where("createdAt", ">=", startingDate),
      where("createdAt", "<", endingDate),
      where("status", "==", APPOINTMENT_STATUS.OPEN)
    );

    const finishedAppointmentsQuery = query(
      collectionRef,
      where("patientId", "==", userId),
      where("isInstantAppointment", "==", isInstantAppointment),
      where("createdAt", ">=", startingDate),
      where("createdAt", "<", endingDate),
      where("status", "==", APPOINTMENT_STATUS.FINISHED)
    );
    const inProgressAppointmentsQuery = query(
      collectionRef,
      where("patientId", "==", userId),
      where("isInstantAppointment", "==", isInstantAppointment),
      where("createdAt", ">=", startingDate),
      where("createdAt", "<", endingDate),
      where("status", "==", APPOINTMENT_STATUS.IN_PROGRESS)
    );

    const noShowAppointmentsRes = await getDocs(noShowAppointmentsQuery);
    const openAppointmentsRes = await getDocs(openAppointmentsQuery);
    const finishedAppointmentsRes = await getDocs(finishedAppointmentsQuery);
    const inProgressAppointmentsRes = await getDocs(
      inProgressAppointmentsQuery
    );

    const noShowAppointments = noShowAppointmentsRes.docs.map((doc) =>
      doc.data()
    );
    const openAppointments = openAppointmentsRes.docs.map((doc) => doc.data());
    const finishedAppointments = finishedAppointmentsRes.docs.map((doc) =>
      doc.data()
    );
    const inProgressAppointments = inProgressAppointmentsRes.docs.map((doc) =>
      doc.data()
    );

    return {
      statusCode: 200,
      message: "Atendimentos solicitados com sucesso!",
      data: [
        ...noShowAppointments,
        ...openAppointments,
        ...finishedAppointments,
        ...inProgressAppointments,
      ],
    };
  } catch (error: any) {
    console.error("[ERROR][getRequestedAppointmentsInLastsDays]", error);

    return {
      statusCode: error.status || 500,
      message: "Não foi possível solicitar os atendimentos dos últimos dias!",
      error,
      data: [],
    };
  }
}

export { getRequestedAppointmentsInLastsDays };
