import { Text, View } from "react-native";

import { Dropdown } from "react-native-element-dropdown";

import { UserProps } from "../../../models/userProps";

import { Feather } from "@expo/vector-icons";
import { styles } from "./styles";
import { getColor } from "../../../styles/colors";
import { companyDefaultTheme } from "../../../../assets/theme/companyColors";
import { useAuth } from "../../../hooks/useAuth";
import { useCompany } from "../../../hooks/useCompany";

interface SelectUserDropdownProps {
  setSelectedUser: React.Dispatch<React.SetStateAction<UserProps>>;
  selectedUser: UserProps;
  users: UserProps[];
}

const SelectUserDropdown = (props: SelectUserDropdownProps) => {
  const { selectedUser, users, setSelectedUser } = props;
  const { user } = useAuth();
  const { company } = useCompany();

  const colors = getColor({ company: company ? company : companyDefaultTheme });

  return (
    <View>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>
          {selectedUser.name
            ? `Atendimento para ${selectedUser.name}`
            : "Selecione um usuário"}
        </Text>
        {user.id === selectedUser.id && (
          <Text style={[styles.indicatorText, { color: colors.primaryColor }]}>
            (Você)
          </Text>
        )}
      </View>
      <Dropdown
        data={users}
        labelField={"name"}
        onChange={(user) => setSelectedUser(user)}
        valueField="name"
        placeholder={
          selectedUser.name ? selectedUser.name : "Selecione um usuário"
        }
        style={styles.dropdown}
        renderLeftIcon={() => (
          <Feather
            name="user"
            style={styles.userIcon}
            color={colors.primaryColor}
          />
        )}
      />
    </View>
  );
};

export { SelectUserDropdown };
