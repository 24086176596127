import axios from "axios";
import { CreateUserProps } from "../../../models/api/user";
import { SimpleApiPropsReturn } from "../../../models/core.props-return";

async function createUserApi(
  props: CreateUserProps,
  accessKey: string
): Promise<SimpleApiPropsReturn> {
  try {
    const response = await axios.post(
      `${process.env.GLEEBEM_API}/user/create`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: accessKey,
        },
      }
    );

    return {
      statusCode: response.status,
      message: "Usuário criado com sucesso!",
      data: response.data,
    };
  } catch (error: any) {
    return {
      message: "Não foi possível criar o usuário, tente novamente mais tarde!",
      statusCode: error.status,
      error: error.response,
    };
  }
}

export { createUserApi };
