import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { firestore } from "../../../config/firebase";

interface SendEmailProps {
  to: string[];
  cc?: string;
  template: "template_bem_vindo" | "template_recuperar_matricula";
  data?: {
    registration: string;
    name: string,
    companyName?: string,
    companyLogo?: string,
    companyPrimaryColor?: string
  };
}

async function sendEmail(props: SendEmailProps) {
  try {
    const { template, to, cc, data } = props;

    const emailRef = collection(firestore, "/emails");

    await addDoc(emailRef, {
      id: emailRef.id,
      to,
      cc: cc || "",
      template: {
        name: template,
        data,
      },
    });
  } catch (error) {
    console.error("[scripts][sendEmail.mjs]", error);
  }
}

export { sendEmail };
