import { StyleSheet } from "react-native";

import { RFValue } from "react-native-responsive-fontsize";

import { getColor } from "../../styles/colors";
import { fonts } from "../../styles/fonts";

import { companyDefaultTheme } from "../../../assets/theme/companyColors";

const colors = getColor({ company: companyDefaultTheme });

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },

  contentWrapper: {
    marginTop: 56,
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
  },

  description: {
    fontFamily: fonts.bold,
    fontSize: RFValue(14),
    color: colors.text,
  },
  emailCard: {
    backgroundColor: colors.white,
    borderRadius: 20,
    paddingVertical: 20,
    paddingHorizontal: 29,
    height: "auto",
    width: "90%",
    marginVertical: 28,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  calendarIcon: {
    fontSize: RFValue(55),
    color: colors.primaryColor,
  },
  emailTitle: {
    fontSize: RFValue(14),
    color: colors.text,
    fontFamily: fonts.light,
  },
  email: {
    fontSize: RFValue(16),
    color: colors.text,
    fontFamily: fonts.bold,
    width: 200,
  },
  textHelpers: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 26,
    flexWrap: "wrap",
  },
  commonHelperText: {
    fontSize: RFValue(12),
    color: colors.text,
    fontFamily: fonts.light,
    marginRight: 3,
  },
  linkingHelperText: {
    fontSize: RFValue(12),
    color: colors.primaryColor,
    fontFamily: fonts.bold,
    textDecorationLine: "underline",
  },
});

export { styles };
