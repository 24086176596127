import { MaterialCommunityIcons } from "@expo/vector-icons";
import { TouchableOpacity } from "react-native";

import { companyDefaultTheme } from "../../../../assets/theme/companyColors";
import { useCompany } from "../../../hooks/useCompany";
import { getColor } from "../../../styles/colors";
import { styles } from "./styles";

interface SupportButtonProps {
  setShowSupportOptions: React.Dispatch<React.SetStateAction<boolean>>;
}

const SupportButton = (props: SupportButtonProps) => {
  const { setShowSupportOptions } = props;
  const { company } = useCompany();

  const colors = getColor({ company: company ? company : companyDefaultTheme });
  return (
    <TouchableOpacity
      activeOpacity={0.7}
      style={[styles.button, { backgroundColor: colors.tertiaryColor }]}
      onPress={() => setShowSupportOptions(true)}
    >
      <MaterialCommunityIcons
        name="message-reply-text"
        style={styles.buttonIcon}
      />
    </TouchableOpacity>
  );
};

export { SupportButton };
