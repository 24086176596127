function setCycleDaysRange(cycleDay: number) {
  const currentDate = new Date();
  const currentMonthlyCycleDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    cycleDay
  );
  if (currentDate.getTime() >= currentMonthlyCycleDay.getTime()) {
    const nextMonthlyCycleDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      cycleDay
    );

    return {
      startingDate: currentMonthlyCycleDay,
      endingDate: nextMonthlyCycleDay,
    };
  } else {
    const previousMonthlyCycleDay = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      cycleDay
    );

    return {
      startingDate: previousMonthlyCycleDay,
      endingDate: currentMonthlyCycleDay,
    };
  }
}

export { setCycleDaysRange };
