type SpecialtyIdToSpecialtyNameType = {
  [key: number]: string;
};

const specialtyIdToSpecialtyName: SpecialtyIdToSpecialtyNameType = {
  7: "Cardiologia",
  4: "Clínica Geral",
  14: "Dermatologia",
  113: "Endocrinologa Pediátrica",
  15: "Endocrinologia",
  16: "Gastroenterologia",
  18: "Geriatria",
  19: "Ginecologia",
  339: "Homeopatia",
  200: "Homeopatia Pediátrica",
  179: "Medicina da Família",
  26: "Neurologia",
  182: "Neuropediatria",
  34: "Nutrição",
  184: "Nutricionista",
  337: "Nutrologia Adulto",
  187: "Nutrologia Pediátrica",
  132: "Ortopedia e Traumatologia",
  29: "Otorrinolaringologia",
  3: "Pediatria",
  33: "Psicologia",
  32: "Psiquiatria",
  38: "Urologia",
};
export { specialtyIdToSpecialtyName };
