import { StyleSheet } from "react-native";

import { RFValue } from "react-native-responsive-fontsize";

import { getColor } from "../../../styles/colors";
import { fonts } from "../../../styles/fonts";

import { companyDefaultTheme } from "../../../../assets/theme/companyColors";

const colors = getColor({ company: companyDefaultTheme });

const styles = StyleSheet.create({
  cardWrapper: {
    height: "auto",
    display: "flex",
    backgroundColor: colors.white,
    borderRadius: 20,
    alignItems: "center",
    justifyContent: "space-around",
    margin: 12,
    padding: 13,
    flexWrap: "wrap",
    flexDirection: "row",
  },
  textWrapper: {
    width: "auto",
  },
  title: {
    fontFamily: fonts.bold,
    fontSize: RFValue(16),
    color: colors.text,
  },
  description: {
    fontFamily: fonts.light,
    fontSize: RFValue(14),
    color: colors.text,
    width: 240,
  },
  icon: {
    width: 50,
    height: 50,
  },
});

export { styles };
