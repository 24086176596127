enum AuthReducerActionKind {
  UPDATE_USER_DATA = "UPDATE_USER_DATA",
  SIGN_IN = "SIGN_IN",
  SIGN_OUT = "SIGN_OUT",
  UPDATE_SIGN_IN = "UPDATE_SIGN_IN",
}

export interface AuthReducerState {
  isSignIn: boolean;
  userCPF: string | null | undefined;
  userRegistration: string | null | undefined;
}

export interface AuthReducerAction {
  type: AuthReducerActionKind;
  userCPF?: string | null | undefined;
  userRegistration?: string | null | undefined;
  isSignIn?: boolean;
}

const authReducerInitialState: AuthReducerState = {
  isSignIn: false,
  userCPF: "",
  userRegistration: "",
};

function authReducer(state: AuthReducerState, action: AuthReducerAction) {
  switch (action.type) {
    case "UPDATE_USER_DATA":
      return {
        ...state,
        userCPF: action.userCPF,
        userRegistration: action.userRegistration,
        isLoading: false,
      };
    case "SIGN_IN":
      return {
        ...state,
        isSignIn: true,
        isLoading: false,
        userCPF: action.userCPF,
        userRegistration: action.userRegistration,
      };
    case "SIGN_OUT":
      return {
        ...state,
        isSignIn: false,
        isLoading: false,
        userCPF: null,
        userRegistration: null,
      };
    case "UPDATE_SIGN_IN":
      return {
        ...state,
        isSignIn: !!action?.isSignIn,
      };
    default:
      return state;
  }
}

export { authReducer, authReducerInitialState, AuthReducerActionKind };
