import { useEffect, useState } from "react";
import { StatusBar } from "react-native";
import SafeAreaView from "react-native-safe-area-view";

import { NativeStackNavigationProp } from "@react-navigation/native-stack";

import { companyDefaultTheme } from "../../../../assets/theme/companyColors";

import { ParamListBase } from "@react-navigation/native";
import { SpecialtyProps } from "../../../models/doc24/specialtyProps";
import { getColor } from "../../../styles/colors";
import { HeaderWithGoBack } from "../../Headers/HeaderWithGoBack";
import { Doc24ScheduleOnDemandPage } from "../Doc24ScheduleOnDemandPage";
import { SelectDoc24Specialties } from "../SelectDoc24Specialties";

import { useCompany } from "../../../hooks/useCompany";
import { styles } from "./styles";
import { handleGetActiveInstantAppointmentController } from "../../../controllers/appointment/handleGetActiveInstantAppointmentController";
import { useAuth } from "../../../hooks/useAuth";
import { getMonthlyInstantAppointmentsLimit } from "../../../utils/appointment";
import { specialtyIdToSpecialtyName } from "../../../utils/doc24/appointment";
import { APPOINTMENT_STATUS } from "../../../utils/constants";
interface Doc24ScheduleOnDemandPageManagerProps {
  navigation: NativeStackNavigationProp<ParamListBase, string, undefined>;
}

const Doc24ScheduleOnDemandPageManager = (
  props: Doc24ScheduleOnDemandPageManagerProps
) => {
  const { navigation } = props;

  const { user } = useAuth();

  const { company } = useCompany();
  const [isOnFirstStep, setIsOnFirstStep] = useState<boolean>(true);
  const [selectedSpecialty, setSelectedSpecialty] = useState<SpecialtyProps>({
    specilatyId: 4,
    specialty: "Clínica Geral",
  } as any);

  const [blockedSpecialties, setBlockedSpecialties] = useState<number[]>([]);

  const colors = getColor({ company: company ? company : companyDefaultTheme });

  useEffect(() => {
    const handleCheckAppointment = async () => {
      const response = await handleGetActiveInstantAppointmentController({
        user,
        navigation,
        redirect: false,
      });

      const appointmentsFiltered =
        response?.appointments.filter(
          (appointment) =>
            appointment.status === APPOINTMENT_STATUS.IN_PROGRESS ||
            appointment.status === APPOINTMENT_STATUS.FINISHED ||
            appointment.status === APPOINTMENT_STATUS.OPEN
        ) || [];

      const specialtyIdToSpecialtyNameToKeyValue =
        Object.keys(specialtyIdToSpecialtyName).map(key =>
        ({
          specialtyId: parseInt(key),
          specialtyName: specialtyIdToSpecialtyName[parseInt(key)]
        }));

      const blockedSpecialtiesIds: any = specialtyIdToSpecialtyNameToKeyValue?.map(
        (appointment) => {
          const monthlyInstantAppointmentLimit = getMonthlyInstantAppointmentsLimit(
            company.providers[user.plan][user.provider],
            appointment?.specialtyId
          );

          if (
            appointmentsFiltered.length >= monthlyInstantAppointmentLimit.generalLimit &&
            monthlyInstantAppointmentLimit.generalLimit !== -1
          ) {
            return appointment?.specialtyId;
          }

          const specificAppointmentsFiltered =
            appointmentsFiltered.filter(
              (specificAppointment) =>
                specificAppointment?.specialtyId === appointment?.specialtyId
            ) || [];

          if (
            specificAppointmentsFiltered.length >= monthlyInstantAppointmentLimit.specialtyLimit &&
            monthlyInstantAppointmentLimit.specialtyLimit !== -1
          ) {
            return appointment?.specialtyId;
          }
        }
      );

      setBlockedSpecialties(blockedSpecialtiesIds);
    };

    navigation.addListener("focus", handleCheckAppointment);
  }, []);

  return (
    <SafeAreaView style={styles.container} forceInset={{ top: "always" }}>
      <StatusBar backgroundColor={colors.primaryColor} translucent={true} />
      <HeaderWithGoBack
        title="Agenda"
        description="Atendimento instantâneo"
        goBackFunction={() => {
          if (isOnFirstStep) {
            navigation.goBack();
          } else {
            setIsOnFirstStep(true);
          }
        }}
      />
      {isOnFirstStep ? (
        <SelectDoc24Specialties
          selectedSpecialty={selectedSpecialty}
          setIsSpecialtySelected={setIsOnFirstStep}
          setSelectedSpecialty={setSelectedSpecialty}
          blockedSpecialties={blockedSpecialties}
          isRestricted
        />
      ) : (
        <Doc24ScheduleOnDemandPage
          navigation={navigation}
          selectedSpecialty={selectedSpecialty}
          setIsOnFirstStep={setIsOnFirstStep}
        />
      )}
    </SafeAreaView>
  );
};
export { Doc24ScheduleOnDemandPageManager };
