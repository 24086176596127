import { isValid } from "date-fns";

function removeTime(dateString: string) {
  const dt = new Date(dateString);
  if (!isValid(dt)) {
    return new Date();
  }
  dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
  return new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
}

export { removeTime };
