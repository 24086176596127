import React from "react";

import {
  Text,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
} from "react-native";
import { companyDefaultTheme } from "../../../../assets/theme/companyColors";
import { getColor } from "../../../styles/colors";

import {
  FontAwesome5,
  Fontisto,
  MaterialCommunityIcons,
} from "@expo/vector-icons";

import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useCompany } from "../../../hooks/useCompany";
import { styles } from "./styles";

interface SelectAppointmentCardProps extends TouchableOpacityProps {
  isActive: boolean;
  doctorName?: string;
  appointmentTime: string;
  onPress: () => void;
}

const SelectAppointmentCard = (props: SelectAppointmentCardProps) => {
  const { appointmentTime, isActive, doctorName, onPress } = props;

  const appointmentTimeToDate = new Date(appointmentTime);

  const { company } = useCompany();
  const colors = getColor({
    company: company?.primaryColor ? company : companyDefaultTheme,
  });

  return (
    <TouchableOpacity
      activeOpacity={0.7}
      style={
        isActive
          ? { ...styles.activeContainer, borderColor: colors.primaryColor }
          : styles.container
      }
      onPress={onPress}
    >
      {doctorName && (
        <View style={styles.doctorContainer}>
          <Fontisto
            name="doctor"
            style={{ ...styles.icon, color: colors.primaryColor }}
          />
          <Text style={styles.text}>{doctorName}</Text>
        </View>
      )}
      <View style={styles.timeContainer}>
        <View style={styles.timeWrapper}>
          <FontAwesome5
            name="calendar-alt"
            style={{ ...styles.icon, color: colors.primaryColor }}
          />
          <Text style={styles.text}>Data: </Text>
          <Text
            style={{
              ...styles.highlightText,
              color: isActive ? colors.primaryColor : colors.text,
            }}
          >
            {format(appointmentTimeToDate, "d 'de' MMMM 'de' yyyy", {
              locale: ptBR,
            })}
          </Text>
        </View>
        <View style={styles.timeWrapper}>
          <MaterialCommunityIcons
            name="clock-outline"
            style={{ ...styles.icon, color: colors.primaryColor }}
          />
          <Text style={styles.text}>Hora: </Text>
          <Text
            style={{
              ...styles.highlightText,
              color: isActive ? colors.primaryColor : colors.text,
            }}
          >
            {format(appointmentTimeToDate, "HH:mm")}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export { SelectAppointmentCard };
