function defineDateNationality(date: string) {
  const dateWithoutSpaces = date.trim();
  if (/^\d{4}/.test(dateWithoutSpaces)) {
    // if start with year is international standard
    return dateWithoutSpaces?.replaceAll("/", "-");
  }

  return dateWithoutSpaces?.replaceAll("-", "/");
}

export { defineDateNationality };
