import { ReactNode } from "react";
import { Text, View } from "react-native";
import { companyDefaultTheme } from "../../../../assets/theme/companyColors";
import { getColor } from "../../../styles/colors";
import { styles } from "./styles";
import { useCompany } from "../../../hooks/useCompany";

interface CardWithStatusHeaderProps {
  children: ReactNode;
  status: {
    text: string;
    color: string;
  };
  flag?: string;
}

const CardWithStatusHeader = (props: CardWithStatusHeaderProps) => {
  const { status, flag, children } = props;
  const { company } = useCompany();
  const colors = getColor({
    company: company?.primaryColor ? company : companyDefaultTheme,
  });

  return (
    <View style={styles.statusWrapper}>
      <View style={styles.statusTextWrapper}>
        <Text
          style={[styles.statusTextCompliment, { color: colors.primaryColor }]}
        >
          Teleconsulta
        </Text>
        <Text style={[styles.cardStatus, { color: status?.color }]}>
          {status.text}
        </Text>

        {!!flag && (
          <View
            style={[
              styles.flagWrapper,
              { backgroundColor: colors.secondaryColor },
            ]}
          >
            <Text style={styles.flagText}>{flag}</Text>
          </View>
        )}
      </View>
      {children}
    </View>
  );
};

export { CardWithStatusHeader };
