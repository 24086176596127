enum Plans {
  BASIC = "basic",
  INTERMEDIATE = "intermediate",
  PREMIUM = "premium",
}

enum Providers {
  DR_TIS = "dr_tis",
  DOC24 = "doc24",
  ALBERT_EINSTEIN = "albert_einstein",
}

export { Plans, Providers };
