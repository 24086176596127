import {
  DocumentData,
  collection,
  getDocs,
  limit,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "../../../config/firebase";

interface getCompanyByKeyPropsReturn {
  message: string;
  errorCode?: string;
  errorMessage?: string;
  data?: DocumentData;
}

async function getCompanyByKey(
  key: string
): Promise<getCompanyByKeyPropsReturn> {
  try {
    const companyQuery = query(
      collection(firestore, "companies"),
      where("key", "==", key),
      limit(1)
    );

    const response = await getDocs(companyQuery);

    if (response.docs.length) {
      return {
        message: "Empresa encontrada com sucesso!",
        data: response.docs[0].data(),
      };
    }

    return {
      message: "Não foi possível encontrar a empresa!",
      errorCode: "404",
      errorMessage: `[ERROR][getCompanyByKey.ts] amount of companies: ${response.docs.length}`,
      data: {},
    };
  } catch (error: any) {
    console.error("[ERROR][getCompanyByKey.ts]", error);
    return {
      message: "Empresa não encontrada!",
      errorCode: error?.code,
      errorMessage: error?.message,
    };
  }
}

export { getCompanyByKey };
