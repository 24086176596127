import {
  Text,
  TouchableOpacity,
  TouchableOpacityProps,
  Image,
} from "react-native";

import { SpecialtyProps } from "../../../models/doc24/specialtyProps";

import Toast from "react-native-root-toast";
import { companyDefaultTheme } from "../../../../assets/theme/companyColors";
import { useCompany } from "../../../hooks/useCompany";
import { getColor } from "../../../styles/colors";
import { styles } from "./styles";

interface SpecialtyCardProps extends TouchableOpacityProps {
  specialtyData: SpecialtyProps;
  icon: any;
  isSelected: boolean;
  handlePress: () => void;
  disabled?: boolean;
  disabledMessage?: string;
}

const SpecialtyCard = (props: SpecialtyCardProps) => {
  const { company } = useCompany();
  const {
    specialtyData,
    icon,
    handlePress,
    isSelected,
    disabled,
    disabledMessage,
  } = props;

  const colors = getColor({ company: company ? company : companyDefaultTheme });

  return (
    <TouchableOpacity
      activeOpacity={0.7}
      style={[
        styles.container,
        {
          borderColor: isSelected ? colors.transparent : colors.primaryColor,
          backgroundColor: isSelected ? colors.primaryColor : colors.white,
        },
        disabled && {
          opacity: 0.7,
          borderColor: colors.grey,
          backgroundColor: colors.white,
        },
      ]}
      onPress={() => {
        if (disabled) {
          Toast.show(disabledMessage || "", {
            duration: Toast.durations.LONG,
          });
        } else {
          handlePress();
        }
      }}>
      <Image
        source={icon}
        style={[
          styles.icon,
          {
            tintColor: isSelected ? colors.white : colors.primaryColor,
          },
        ]}
      />

      <Text
        style={[
          styles.specialtyText,
          { color: isSelected ? colors.white : colors.primaryColor },
          disabled && { color: colors.grey },
        ]}>
        {specialtyData.specialty}
      </Text>
    </TouchableOpacity>
  );
};

export { SpecialtyCard };
