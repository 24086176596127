const APPOINTMENT_STATUS = Object.freeze({
  OPEN: "open",
  CANCELLED: "cancelled",
  ABANDON: "abandon",
  IN_PROGRESS: "inProgress",
  NO_SHOW: "noShow",
  FINISHED: "finished",
});

export { APPOINTMENT_STATUS };
