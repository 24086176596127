enum RegistrationDataActionKind {
  UPDATE_NAME = "UPDATE_NAME",
  UPDATE_LAST_NAME = "UPDATE_LAST_NAME",
  UPDATE_CPF = "UPDATE_CPF",
  UPDATE_EMAIL = "UPDATE_EMAIL",
  UPDATE_PHONE = "UPDATE_PHONE",
  UPDATE_GENDER = "UPDATE_GENDER",
  UPDATE_BIRTHDATE = "UPDATE_BIRTHDATE",
  SET_FULL_DATA = "SET_FULL_DATA",
}

export interface RegistrationDataReducerState {
  name: string;
  lastName: string;
  cpf: string;
  email: string;
  phone: string;
  gender: "Masculino" | "Feminino" | "";
  birthdate: string;
}

export interface RegistrationDataAction {
  type: RegistrationDataActionKind;
  name?: string;
  lastName?: string;
  cpf?: string;
  email?: string;
  phone?: string;
  gender?: "Masculino" | "Feminino" | "";
  birthdate?: string;
}

const registrationDataReducerInitialState: RegistrationDataReducerState = {
  name: "",
  lastName: "",
  cpf: "",
  email: "",
  phone: "",
  gender: "",
  birthdate: "",
};

function registrationDataReducer(
  state: RegistrationDataReducerState,
  action: RegistrationDataAction
): RegistrationDataReducerState {
  switch (action.type) {
    case "UPDATE_NAME":
      return {
        ...state,
        name: action.name || "",
      };
    case "UPDATE_LAST_NAME":
      return {
        ...state,
        lastName: action.lastName || "",
      };
    case "UPDATE_CPF":
      return {
        ...state,
        cpf: action.cpf || "",
      };
    case "UPDATE_EMAIL":
      return {
        ...state,
        email: action.email || "",
      };
    case "UPDATE_PHONE":
      return {
        ...state,
        phone: action.phone || "",
      };
    case "UPDATE_GENDER":
      return {
        ...state,
        gender: action.gender || "",
      };
    case "UPDATE_BIRTHDATE":
      return {
        ...state,
        birthdate: action.birthdate || "",
      };
    case "SET_FULL_DATA":
      return {
        ...state,
        name: action.name || "",
        cpf: action.cpf || "",
        email: action.email || "",
        phone: action.phone || "",
        gender: action.gender || "",
        birthdate: action.birthdate || "",
      };
    default:
      return state;
  }
}

export {
  registrationDataReducer,
  registrationDataReducerInitialState,
  RegistrationDataActionKind,
};
