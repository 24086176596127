function getSubdomainName(url: string) {
  if (!url) {
    return "";
  }

  const matches = url.match(/^(?:https?:\/\/)?(?:www\d*\.)?(.*?)\./i);

  if (matches?.length) {
    return matches[1];
  }
}

export { getSubdomainName };
