function formatHiddenEmail(email: string) {
  return email
    .replace(
      /(?<=..).+@/g,
      (c: string) =>
        c
          .split("")
          .slice(0, -1)
          .map((v) => "*")
          .join("") + "@"
    )
    .replace(/(?<=@.)([^.]+)(?=\.com)/g, (c) =>
      c
        .split("")
        .slice(0, -1)
        .map((v) => "*")
        .join("")
    );
}

export { formatHiddenEmail };
