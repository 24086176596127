import { StyleSheet } from "react-native";

import { RFValue } from "react-native-responsive-fontsize";

import { getColor } from "../../../styles/colors";
import { fonts } from "../../../styles/fonts";

import { companyDefaultTheme } from "../../../../assets/theme/companyColors";

const colors = getColor({ company: companyDefaultTheme });

const styles = StyleSheet.create({
  cardWrapper: {
    width: "100%",
    height: "auto",
    paddingVertical: 10,
    flexWrap: "nowrap",
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "center",
    paddingHorizontal: RFValue(20),
    marginBottom: 10,
    borderWidth: 2,
    borderColor: colors.neutralGray,
    borderRadius: 20,
  },
  stateAcronym: {
    fontSize: RFValue(17),
    color: colors.primaryColor,
    fontFamily: fonts.bold,
    marginRight: 10,
  },
  phone: {
    fontSize: RFValue(15),
    color: colors.midGrey,
    fontFamily: fonts.bold,
    marginLeft: 10,
  },
});

export { styles };
