import { StyleSheet } from "react-native";

import { RFValue } from "react-native-responsive-fontsize";

import { getColor } from "../../../styles/colors";
import { fonts } from "../../../styles/fonts";

import { companyDefaultTheme } from "../../../../assets/theme/companyColors";

const colors = getColor({ company: companyDefaultTheme });

const styles = StyleSheet.create({
  infoWrapper: {
    display: "flex",
    width: "auto",
    height: "auto",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 14,
    paddingBottom: 14,
    paddingLeft: 17,
    paddingRight: 17,
    backgroundColor: colors.white,
    borderRadius: 20,
    marginTop: 25,
    marginBottom: 20,
  },
  modalTextInfoWrapper: {
    marginLeft: 13,
  },
  modalInfoTextTitle: {
    fontFamily: fonts.bold,
    fontSize: RFValue(16),
    color: colors.tertiaryColor,
  },
  textLine: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
  },
  separator: {
    width: 10,
    height: 10,
    borderRadius: 50,
    marginHorizontal: 5,
  },
  smallModalText: {
    display: "flex",
    flexDirection: "row",
    fontFamily: fonts.light,
    fontSize: RFValue(12),
    color: colors.text,
  },
  specialtyName: {
    display: "flex",
    flexDirection: "row",
    fontFamily: fonts.light,
    fontSize: RFValue(12),
    color: colors.text,
    fontStyle: "italic",
  },
  modalDescriptionText: {
    fontFamily: fonts.light,
    fontSize: RFValue(14),
    color: colors.text,
  },
  doctorIcon: {
    fontSize: RFValue(22),
    color: colors.tertiaryColor,
  },
});

export { styles };
