import { StyleSheet, Dimensions } from "react-native";

import { RFValue } from "react-native-responsive-fontsize";

import { getColor } from "../../../styles/colors";
import { fonts } from "../../../styles/fonts";

import { companyDefaultTheme } from "../../../../assets/theme/companyColors";

const colors = getColor({ company: companyDefaultTheme });

const styles = StyleSheet.create({
  container: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
    flex: 1,
    padding: 10,
  },
  infoText: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: fonts.medium,
    fontSize: RFValue(15),
    color: colors.text,
    margin: "auto",
  },
});

export { styles };
