import { Linking, StatusBar, Text, View } from "react-native";

import { getColor } from "../../styles/colors";

import { companyDefaultTheme } from "../../../assets/theme/companyColors";

import { NativeStackHeaderProps } from "@react-navigation/native-stack";
import { SafeAreaView } from "react-native-safe-area-context";
import { DefaultButton } from "../../components/Buttons/DefaultButton";
import { useCompany } from "../../hooks/useCompany";
import { styles } from "./styles";

import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useRoute } from "@react-navigation/native";
import Toast from "react-native-root-toast";
import { HeaderWithGoBack } from "../../components/Headers/HeaderWithGoBack";
import { formatHiddenEmail } from "../../utils/formatters/formatHiddenEmail";

interface PageParams {
  email: string;
}

const RegistrationSent = ({ navigation }: NativeStackHeaderProps) => {
  const { company } = useCompany();

  const route = useRoute();
  const params = route.params as PageParams;
  const colors = getColor({ company: company ? company : companyDefaultTheme });

  if (!params?.email) {
    Toast.show("Email para enviar matrícula não identificado!", {
      duration: Toast.durations.LONG,
    });
  }

  return (
    <SafeAreaView style={styles.container}>
      <StatusBar
        barStyle={"light-content"}
        backgroundColor={colors.primaryColor}
        translucent={true}
      />

      <HeaderWithGoBack
        goBackFunction={() => {
          navigation.navigate("Login");
        }}
        title="Recuperar matrícula"
      />
      <View style={styles.contentWrapper}>
        <Text style={styles.description}>
          Sua matrícula foi enviada para o email a seguir:
        </Text>

        <View style={styles.emailCard}>
          <View>
            <MaterialCommunityIcons
              name="calendar-month"
              style={styles.calendarIcon}
            />
          </View>
          <View>
            <Text style={styles.emailTitle}>Email</Text>
            <Text style={styles.email} numberOfLines={1}>
              {params?.email ? formatHiddenEmail(params.email) : "-"}
            </Text>
          </View>
        </View>
        <DefaultButton onPress={() => navigation.navigate("Login")} text="Ok" />
        <View style={styles.textHelpers}>
          <Text style={styles.commonHelperText}>
            Não tem acesso ao email acima ou está incorreto?
          </Text>
          <Text
            style={styles.linkingHelperText}
            onPress={() => {
              Linking.openURL("https://www.gleebem.com.br/?open=true");
            }}
          >
            Fale com nosso suporte.
          </Text>
        </View>
      </View>
    </SafeAreaView>
  );
};

export { RegistrationSent };
