import { Text, View, Platform } from "react-native";

import { MaterialIcons } from "@expo/vector-icons";

import { DefaultButton } from "../../components/Buttons/DefaultButton";
import { InputWithLabel } from "../../components/Inputs/InputWithLabel";
import { styles } from "./styles";

import { companyDefaultTheme } from "../../../assets/theme/companyColors";
import {
  PreRegistrationFormAction,
  PreRegistrationFormActionKind,
  PreRegistrationFormState,
} from "../../reducers/preRegistrations/preRegistrationFormReducer";
import { getColor } from "../../styles/colors";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Toast from "react-native-root-toast";
import { useEffect } from "react";
import { ValidationInput } from "../../components/Inputs/ValidationInput";

interface Step2Props {
  setSelectedStep: React.Dispatch<React.SetStateAction<1 | 2 | 3>>;
  preRegistrationState: PreRegistrationFormState;
  dispatchPreRegistrationState: React.Dispatch<PreRegistrationFormAction>;
}

interface Step2FormData {
  name: string;
  lastName: string;
  phone: string;
  cpf: string;
}

const schema = yup.object().shape({
  name: yup
    .string()
    .required("O nome é um campo obrigatório")
    .min(3, "O nome deve ter no mínimo 3 caracteres"),
  lastName: yup
    .string()
    .required("O sobrenome é um campo obrigatório")
    .min(3, "O sobrenome deve ter no mínimo 3 caracteres"),
  phone: yup.string().required("Telefone é um campo obrigatório").transform(value => value.replace(/\D+/g, '')).min(11, "Verifique o campo de Telefone"),
  cpf: yup.string().required("CPF é um campo obrigatório"),
});

const Step2 = (props: Step2Props) => {
  const {
    setSelectedStep,
    dispatchPreRegistrationState,
    preRegistrationState,
  } = props;

  const colors = getColor({ company: companyDefaultTheme });

  const { control, setValue, getValues } = useForm<Step2FormData>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue("name", preRegistrationState.name);
    setValue("lastName", preRegistrationState.lastName);
    setValue("phone", preRegistrationState.phone);
    setValue("cpf", preRegistrationState.cpf);
  }, [preRegistrationState]);

  async function handleNextStep(props: Step2FormData) {
    try {
      await schema.validate(props);

      dispatchPreRegistrationState({
        type: PreRegistrationFormActionKind.UPDATE_NAME,
        name: props.name,
      });
      dispatchPreRegistrationState({
        type: PreRegistrationFormActionKind.UPDATE_LAST_NAME,
        lastName: props.lastName,
      });
      dispatchPreRegistrationState({
        type: PreRegistrationFormActionKind.UPDATE_PHONE,
        phone: props.phone,
      });
      dispatchPreRegistrationState({
        type: PreRegistrationFormActionKind.UPDATE_CPF,
        cpf: props.cpf,
      });

      setSelectedStep(3);
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        if (Platform.OS !== "web") {
          return Toast.show("Preencha os dados corretamente para prosseguir!", {
            duration: Toast.durations.LONG,
          });
        }
      }

      Toast.show(
        "Algo não deu certo em seu pré-registro. Tente mais tarde...",
        { duration: Toast.durations.LONG }
      );
    }
  }

  return (
    <>
      <View style={styles.registrationDataContainer}>
        <View style={styles.registrationDataWrapper}>
          <View style={styles.header}>
            <View style={styles.stepIndicatorWrapper}>
              <Text style={styles.currentStepText}>2</Text>
              <Text style={styles.totalStepsText}>/3</Text>
            </View>
            <View style={styles.stepTitleWrapper}>
              <MaterialIcons
                name="app-registration"
                style={styles.registrationIcon}
              />
              <Text style={styles.headerText}>Preencha seus dados</Text>
            </View>
          </View>
          <Text style={styles.headerText}>
            Verifique se seus outros dados estão corretos:
          </Text>

          <View>
            <ValidationInput
              control={control}
              name={"name"}
              label="Nome"
              additionalLabelStyles={{ marginTop: 17 }}
            />

            <ValidationInput
              control={control}
              name={"lastName"}
              label="Sobrenome"
              additionalLabelStyles={{ marginTop: 17 }}
            />

            <ValidationInput
              control={control}
              name="phone"
              label="Telefone"
              onChangeText={(text) => {
                dispatchPreRegistrationState({
                  type: PreRegistrationFormActionKind.UPDATE_PHONE,
                  phone: text,
                });
              }}
              additionalLabelStyles={{ marginTop: 17 }}
              value={preRegistrationState.phone}
              maskType="custom"
              inputType="with-mask"
              options={{
                withDDD: true,
                maskType: "BRL",
                dddMask: "(99)",
                mask: "(99) 9 9999-9999",
              }}
              inputMode="tel"
            />
            <ValidationInput
              control={control}
              name="cpf"
              label="CPF"
              onChangeText={(text) => {
                dispatchPreRegistrationState({
                  type: PreRegistrationFormActionKind.UPDATE_CPF,
                  cpf: text,
                });
              }}
              additionalLabelStyles={{ marginTop: 17 }}
              value={preRegistrationState.cpf}
              maskType="cpf"
              inputType="with-mask"
              editable={false}
            />
          </View>
        </View>
      </View>

      <View style={styles.buttonWrapper}>
        <DefaultButton
          text={"Voltar"}
          onPress={async () => {
            setSelectedStep(1);
          }}
          additionalStyles={{
            marginTop: 20,
            backgroundColor: colors.transparent,
            borderWidth: 2,
            marginRight: 50,
            borderColor: colors.primaryColor,
          }}
          buttonWidth={230}
          textColor={colors.primaryColor}
        />
        <DefaultButton
          text={"Continuar"}
          onPress={async () => handleNextStep(getValues())}
          additionalStyles={{ marginTop: 20 }}
          buttonWidth={230}
        />
      </View>
    </>
  );
};

export { Step2 };
