import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../../config/firebase";

async function getDependents(holderId: string) {
  try {
    const dependentsQuery = query(
      collection(firestore, "users"),
      where("dependentOn.id", "==", holderId),
      where("id", "!=", holderId)
    );

    const response = await getDocs(dependentsQuery);

    return {
      message: "Dependentes encontrados com sucesso!",
      data: response.docs.map((dependent) => dependent.data()),
    };
  } catch (error: any) {
    console.error("[ERROR] [getDependents.tsx]", error);

    return {
      message:
        "Erro inesperado. Não foi possível encontrar os dependentes do usuário",
      errorCode: error.code,
      errorMessage: error.message,
      data: {},
    };
  }
}

export { getDependents };
