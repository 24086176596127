import { StyleSheet } from "react-native";

import { RFValue } from "react-native-responsive-fontsize";

import { getColor } from "../../../styles/colors";
import { fonts } from "../../../styles/fonts";

import { companyDefaultTheme } from "../../../../assets/theme/companyColors";

const colors = getColor({ company: companyDefaultTheme });

const styles = StyleSheet.create({
  textLabel: {
    fontSize: RFValue(12),
    fontFamily: fonts.bold,
  },
  textInputStyle: {
    width: "100%",
    height: "auto",
    paddingHorizontal: 12,
    paddingVertical: 5,
    backgroundColor: colors.platinum,
    borderBottomWidth: 1,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  textInputStyleActive: {
    width: "100%",
    height: "auto",
    paddingHorizontal: 12,
    paddingVertical: 5,
    backgroundColor: colors.platinum,
    borderWidth: 1,
    borderRadius: 10,
  },
  errorText: {
    fontSize: RFValue(12),
    fontFamily: fonts.bold,
    color: colors.textRed,
  },
});

export { styles };
