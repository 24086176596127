import {
  DocumentData,
  collection,
  getDocs,
  limit,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "../../../config/firebase";

interface GetCompanyByIdPropsReturn {
  message: string;
  errorCode?: string;
  errorMessage?: string;
  data?: DocumentData;
}

async function getCompanyById(id: string): Promise<GetCompanyByIdPropsReturn> {
  try {
    const companyQuery = query(
      collection(firestore, "companies"),
      where("id", "==", id),
      limit(1)
    );

    const response = await getDocs(companyQuery);

    if (response.docs.length) {
      return {
        message: "Empresa encontrada com sucesso!",
        data: response.docs[0].data(),
      };
    }

    return {
      message: "Não foi possível encontrar a empresa!",
      errorCode: "404",
      errorMessage: `[ERROR][getCompanyById.ts] amount of companies: ${response.docs.length}`,
      data: {},
    };
  } catch (error: any) {
    console.error("[ERROR][getCompanyById.ts]", error);
    return {
      message: "Empresa não encontrada!",
      errorCode: error?.code,
      errorMessage: error?.message,
    };
  }
}

export { getCompanyById };
