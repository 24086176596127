import { CardWithStatusBody } from "./CardWithStatusBody";
import { CardWithStatusButton } from "./CardWithStatusButton";
import { CardWithStatusHeader } from "./CardWithStatusHeader";
import { CardWithStatusIcon } from "./CardWithStatusIcon";
import { CardWithStatusRoot } from "./CardWithStatusRoot";
import { CardWithStatusText } from "./CardWithStatusText";

const CardWithStatus = {
  Root: CardWithStatusRoot,
  Header: CardWithStatusHeader,
  Body: CardWithStatusBody,
  Icon: CardWithStatusIcon,
  Text: CardWithStatusText,
  Button: CardWithStatusButton,
};

export { CardWithStatus };
