import {
  KeyboardAvoidingView,
  Linking,
  Platform,
  StatusBar,
  Text,
  ScrollView,
  View,
} from "react-native";

import { getColor } from "../../styles/colors";

import { companyDefaultTheme } from "../../../assets/theme/companyColors";

import { NativeStackHeaderProps } from "@react-navigation/native-stack";
import { useEffect, useState } from "react";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import { TextInputMask } from "react-native-masked-text";
import { SafeAreaView } from "react-native-safe-area-context";
import { DefaultButton } from "../../components/Buttons/DefaultButton";
import { getCompanyById } from "../../services/firestore/company/getCompanyById";
import { getUserByCpf } from "../../services/firestore/user/getUserByCpf";
import { styles } from "./styles";

import { DocumentData } from "firebase/firestore";
import { HeaderWithGoBack } from "../../components/Headers/HeaderWithGoBack";
import { sendEmail } from "../../services/firestore/email/sendEmail";
import { BasicLoading } from "../../components/Loadings/BasicLoading";

const ForgetRegistration = ({ navigation }: NativeStackHeaderProps) => {
  const colors = getColor({ company: companyDefaultTheme });
  const [company, setCompany] = useState<any>({} as any);

  const [userFound, setUserFound] = useState<DocumentData>({} as DocumentData);
  const [cpf, setCpf] = useState<string>("");

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [isInputActive, setIsInputActive] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const activeInputStyle = {
    borderWidth: 1,
    borderColor: colors.neutralGray,
  };

  async function handleGetUserByCpf() {
    setIsLoading(true);
    if (!cpf) {
      setCpf("");
      setErrorMessage("Informe um CPF válido!");
      setIsLoading(false);
      return;
    }

    setErrorMessage("");

    const response = await getUserByCpf({
      cpf: cpf.replaceAll(".", "").replace("-", ""),
    });
    const companyResponse = await getCompanyById(response.data.companyId);
    setCompany(companyResponse.data);

    if (!response.data.id) {
      setIsLoading(false);
      return setErrorMessage("CPF não encontrado.");
    }

    if (response.data?.email) {
      setUserFound(response.data);
    } else {
      setErrorMessage("Usuário não possui email.");
    }
    setIsLoading(false);
    return;
  }

  useEffect(() => {
    setIsLoading(true);
    const handleSendEmail = async () => {
      if (!userFound?.email) {
        setIsLoading(false);
        return;
      }

      await sendEmail({
        template: "template_recuperar_matricula",
        to: userFound.email,
        data: {
          name: userFound.name.split(" ")[0],
          registration: userFound.registration,
          companyName: company.name || "",
          companyLogo: company.logo || "",
          companyPrimaryColor: company.primaryColor || "",
        },
      });

      setIsLoading(false);
      navigation.navigate("Matrícula Enviada", {
        email: userFound.email,
      });

      setCpf("");
      setUserFound({});
    };

    handleSendEmail();
  }, [userFound]);

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <StatusBar
        barStyle={"light-content"}
        backgroundColor={colors.primaryColor}
        translucent={true}
      />

      <HeaderWithGoBack
        goBackFunction={() => {
          navigation.navigate("Login");
        }}
        title="Recuperar matrícula"
      />
      <View style={styles.container}>
        {isLoading ? (
          <BasicLoading />
        ) : (
          <KeyboardAvoidingView
            behavior={Platform.OS === "ios" ? "padding" : "height"}
          >
            <View style={styles.contentWrapper}>
              <Text style={styles.label}>Informe seu CPF</Text>

              <View style={styles.formWrapper}>
                <TextInputMask
                  type="cpf"
                  placeholder="CPF"
                  style={[isInputActive && activeInputStyle, styles.input]}
                  onChangeText={(currentText) => {
                    setCpf(currentText);
                  }}
                  value={cpf}
                  onFocus={() => {
                    setIsInputActive(true);
                  }}
                  onBlur={() => setIsInputActive(false)}
                />
                {errorMessage && (
                  <Text style={styles.errorText}>{errorMessage}</Text>
                )}
                <DefaultButton
                  onPress={async () => {
                    await handleGetUserByCpf();
                  }}
                  text="Continuar"
                />
                <DefaultButton
                  onPress={() => {
                    setIsInputActive(false);
                    setCpf("");
                    setCompany({});
                    navigation.navigate("Login");
                  }}
                  text="Voltar"
                  additionalStyles={{
                    backgroundColor: "transparent",
                    borderWidth: 1,
                    borderColor: colors.primaryColor,
                    marginTop: 16,
                  }}
                  textColor={colors.primaryColor}
                />

                <View style={styles.textHelpers}>
                  <Text style={styles.commonHelperText}>Precisa de ajuda?</Text>
                  <Text
                    style={styles.linkingHelperText}
                    onPress={() => {
                      Linking.openURL("https://www.gleebem.com.br/?open=true");
                    }}
                  >
                    Fale com nosso suporte.
                  </Text>
                </View>
              </View>
            </View>
          </KeyboardAvoidingView>
        )}
      </View>
    </SafeAreaView>
  );
};

export { ForgetRegistration };
