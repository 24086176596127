import { CardWithStatus } from "./index";
import { companyDefaultTheme } from "../../../../assets/theme/companyColors";
import { useCompany } from "../../../hooks/useCompany";
import { AppointmentProps } from "../../../models/appointmentProps";
import { getColor } from "../../../styles/colors";
import { specialtyIdToSpecialtyName } from "../../../utils/doc24/appointment";

interface CardWithStatusBodyProps {
  appointment: AppointmentProps;
  appointmentTimeText: string;
  isDependent: boolean;
  appointmentAvailable: boolean;
  textStatusCard: string;
  subTitleText?: string;
}

const CardWithStatusBody = (props: CardWithStatusBodyProps) => {
  const {
    appointment,
    appointmentTimeText,
    isDependent,
    appointmentAvailable,
    textStatusCard,
    subTitleText,
  } = props;
  const { company } = useCompany();
  const colors = getColor({
    company: company?.primaryColor ? company : companyDefaultTheme,
  });

  return (
    <>
      {appointment?.specialtyId && (
        <CardWithStatus.Text
          text={specialtyIdToSpecialtyName[appointment.specialtyId]}
          color={colors.text}
          textStyle={"specialty"}
        />
      )}

      <CardWithStatus.Text text={appointmentTimeText} textStyle="card" />

      {isDependent && (
        <CardWithStatus.Text
          text={appointment?.patient?.name}
          textStyle="compliment"
        />
      )}
      {appointmentAvailable && !!subTitleText && (
        <CardWithStatus.Text
          text={subTitleText}
          textStyle="subTitle"
          color={textStatusCard === "danger" ? colors.textRed : ""}
        />
      )}
      {(appointmentAvailable || appointment.status === "inProgress") && (
        <CardWithStatus.Button text="Entrar na sala" />
      )}
    </>
  );
};

export { CardWithStatusBody };
