import { StyleSheet } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";
import { getColor } from "../../../styles/colors";
import { companyDefaultTheme } from "../../../../assets/theme/companyColors";
import { fonts } from "../../../styles/fonts";

const colors = getColor({ company: companyDefaultTheme });

const styles = StyleSheet.create({
  back: {
    width: 200,
    height: "auto",
    padding: 10,
    marginHorizontal: 200,
    justifyContent: "flex-start",
    alignContent: "flex-start",
    borderRadius: 20,
    flexDirection: "row",
    alignItems: "center",
  },

  backText: {
    fontFamily: fonts.bold,
    fontSize: RFValue(15),
    color: colors.white,
  },

  arrowBack: {
    height: 30,
    width: 30,
    color: colors.white,
    marginRight: 10,
  },
});

export { styles };
