import { Text, TouchableOpacity } from "react-native";

import { FontAwesome5 } from "@expo/vector-icons";
import { companyDefaultTheme } from "../../../../assets/theme/companyColors";
import { useCompany } from "../../../hooks/useCompany";
import { getColor } from "../../../styles/colors";
import { styles } from "./styles";

interface HistoryCardProps {
  textContent: string;
  onPress: () => void;
}

const HistoryCard = (props: HistoryCardProps) => {
  const { textContent, onPress } = props;

  const { company } = useCompany()

  const colors = getColor({
    company: company.primaryColor ? company : companyDefaultTheme,
  });

  return (
    <TouchableOpacity
      style={styles.cardWrapper}
      activeOpacity={0.7}
      onPress={onPress}
    >
      <FontAwesome5
        name={"history"}
        size={25}
        color={colors.tertiaryColor}
        style={styles.icon}
      />
      <Text style={styles.text}>{textContent}</Text>
    </TouchableOpacity>
  );
};

export { HistoryCard };

