import { Text, View } from "react-native";
import { companyDefaultTheme } from "../../../../assets/theme/companyColors";
import { useCompany } from "../../../hooks/useCompany";
import { getColor } from "../../../styles/colors";
import { styles } from "./styles";

interface CardWithStatusButtonProps {
  text: string;
}

const CardWithStatusButton = (props: CardWithStatusButtonProps) => {
  const { text } = props;
  const { company } = useCompany();
  const colors = getColor({
    company: company?.primaryColor ? company : companyDefaultTheme,
  });

  return (
    <View
      style={[styles.joinRoomButton, { backgroundColor: colors.primaryColor }]}
    >
      <Text style={styles.jointBtnText}>{text}</Text>
    </View>
  );
};

export { CardWithStatusButton };
