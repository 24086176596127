import { Text } from "react-native";
import { styles } from "./styles";

interface CardWithStatusTextProps {
  text: string;
  color?: string;
  textStyle: "subTitle" | "specialty" | "card" | "compliment";
}

const textStyleSet = {
  subTitle: styles.subTitle,
  specialty: styles.specialtyName,
  card: styles.cardText,
  compliment: styles.compliment,
};

const CardWithStatusText = (props: CardWithStatusTextProps) => {
  const { text, textStyle, color } = props;

  const selectedTextStyle = textStyleSet[textStyle];

  return (
    <Text style={[selectedTextStyle, !!color ? { color } : {}]}>{text}</Text>
  );
};

export { CardWithStatusText };
