import {
  DocumentData,
  collection,
  getDocs,
  limit,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "../../../config/firebase";

interface getUserByCpfProps {
  cpf: string;
}

interface GetUserByCpfPropsReturn {
  errorCode?: string;
  errorMessage?: string;
  message: string;
  data: DocumentData;
}

async function getUserByCpf(
  props: getUserByCpfProps
): Promise<GetUserByCpfPropsReturn> {
  const { cpf } = props;

  try {
    const userQuery = query(
      collection(firestore, "users"),
      where("cpf", "==", cpf),
      where("isActive", "==", true),
      limit(1)
    );
    const response = await getDocs(userQuery);

    if (response.docs.length) {
      return {
        message: "Usuário encontrado com sucesso.",
        data: response.docs[0].data(),
      };
    }

    return {
      message: "Não foi possível encontrar o usuário!",
      errorCode: "404",
      data: {},
    };
  } catch (error: any) {
    console.error("[ERROR][getUserByCpf.ts]", error);
    return {
      message: "Não foi possível encontrar o usuário!",
      errorCode: error.code,
      errorMessage: error.message,
      data: {},
    };
  }
}

export { getUserByCpf };
