import { useEffect, useState } from "react";
import { View, Text, Animated } from "react-native";
import { styles } from "./styles";

interface NoDataMessageProps {
  text: string;
}

const NoDataMessage = (props: NoDataMessageProps) => {
  const opacity = useState(new Animated.Value(0))[0];

  useEffect(() => {
    Animated.timing(opacity, {
      toValue: 1,
      duration: 1000,
      useNativeDriver: true,
    }).start();
  }, []);

  const { text } = props;
  return (
    <Animated.View style={styles.messageContainer}>
      <Text style={styles.messageText}>{text}</Text>
    </Animated.View>
  );
};

export { NoDataMessage };
