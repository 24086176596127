import { StyleSheet } from "react-native";

import { RFValue } from "react-native-responsive-fontsize";

import { getColor } from "../../styles/colors";
import { fonts } from "../../styles/fonts";

import { companyDefaultTheme } from "../../../assets/theme/companyColors";

const colors = getColor({ company: companyDefaultTheme });

const styles = StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  scrollView: {
    display: "flex",
    width: "100%",
  },
  contentWrapper: {
    marginTop: 47,
  },
  instructionTitle: {
    fontFamily: fonts.bold,
    fontSize: RFValue(22),
    color: colors.midBlack,
  },
  instructionDescription: {
    fontFamily: fonts.bold,
    fontSize: RFValue(14),
    color: colors.lightBlack,
  },
  formWrapper: {
    marginTop: 36,
  },

  input: {
    width: "100%",
    height: 59,
    backgroundColor: colors.white,
    borderRadius: 20,
    marginBottom: 6,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 18,
    paddingRight: 18,
  },
  errorText: {
    fontSize: RFValue(13),
    fontFamily: fonts.light,
    color: colors.textRed,
    textAlign: "center",
    marginBottom: 10,
  },
  recoverRegistrationText: {
    marginTop: 10,
    marginBottom: 10,
    textAlign: "center",
    fontSize: RFValue(12),
    fontFamily: fonts.bold,
    color: colors.primaryColor,
    textDecorationLine: 'underline'
  },
});

export { styles };
