import React from "react";
import { StyleProp, View, ViewStyle, Image } from "react-native";

import { styles } from "./styles";
import { getColor } from "../../../styles/colors";
import { companyDefaultTheme } from "../../../../assets/theme/companyColors";

interface BasicLoadingProps {
  additionalStyles?: StyleProp<ViewStyle>;
  isFullScreen?: boolean;
}

const BasicLoading = (props: BasicLoadingProps) => {
  const colors = getColor({ company: companyDefaultTheme });

  const fullScreenStyles: StyleProp<ViewStyle> = {
    flex: 1,
    backgroundColor: colors.white,
    position: "absolute",
  };

  return (
    <View
      style={[
        styles.loadingContainer,
        props.isFullScreen && fullScreenStyles,
        props?.additionalStyles,
      ]}
    >
      <Image
        source={require("../../../../assets/gifs/loading.gif")}
        style={styles.animation}
      />
    </View>
  );
};

export { BasicLoading };
