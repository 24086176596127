import { NativeStackHeaderProps } from "@react-navigation/native-stack";

import { useAuth } from "../../hooks/useAuth";

import { BasicLoading } from "../../components/Loadings/BasicLoading";
import { Doc24ScheduleAppointmentManager } from "../../components/Pages/Doc24ScheduleAppointmentManager";
import { DrTisScheduleAppointmentPage } from "../../components/Pages/DrTisScheduleAppointmentPage";

import { ProviderUnavailable } from "../../components/Pages/ProviderUnavailable";

import { useEffect } from "react";
import { handleGetActiveAppointmentController } from "../../controllers/appointment/handleGetActiveAppointmentController";
import { Providers } from "../../models/gleebem.core";

const ScheduleAppointment = ({ navigation }: NativeStackHeaderProps) => {
  const { user } = useAuth();

  useEffect(() => {
    if (user.provider === Providers.DR_TIS) {
      navigation.addListener("focus", () =>
        handleGetActiveAppointmentController({ user, navigation })
      );
    }
  }, []);

  if (!user) {
    return <BasicLoading />;
  }

  if (user.provider === Providers.DR_TIS) {
    return <DrTisScheduleAppointmentPage navigation={navigation} />;
  } else if (user.provider === Providers.DOC24) {
    return <Doc24ScheduleAppointmentManager navigation={navigation} />;
  }
  return <ProviderUnavailable />;
};

export { ScheduleAppointment };
