import { differenceInYears } from "date-fns";

interface ValidateEmailAndBirthdateProps {
  email: string;
  birthdate: string;
  customEmailDomain?: string;
  customMaxAge?: number;
}

function validateEmailAndBirthdate(props: ValidateEmailAndBirthdateProps) {
  const { birthdate, email, customEmailDomain, customMaxAge } = props;

  const defaultEmailDomain =
    customEmailDomain || process.env.TEMPORARY_EMAIL_DOMAIN;
  const defaultMaxAge = customMaxAge || 120;

  const currentDate = new Date();

  const isBirthdateValid =
    differenceInYears(currentDate, new Date(birthdate)) < defaultMaxAge;
  const isEmailValid = !email.includes(defaultEmailDomain);

  return { isBirthdateValid, isEmailValid };
}

export { validateEmailAndBirthdate };
