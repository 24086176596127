import { StyleSheet } from "react-native";
import { RFValue } from "react-native-responsive-fontsize";
import { getColor } from "../../styles/colors";
import { companyDefaultTheme } from "../../../assets/theme/companyColors";
import { fonts } from "../../styles/fonts";

const colors = getColor({ company: companyDefaultTheme });

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    width: "100%",
    height: "auto",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  closeButton: {
    width: 50,
    height: 50,
    justifyContent: "center",
    alignContent: "center",
    alignSelf: "flex-end",
  },
  closeIcon: {
    color: colors.white,
    fontSize: RFValue(15),
    alignSelf: "center",
  },
  globeIcon: {
    color: colors.white,
    fontSize: RFValue(20),
    marginRight: 10,
  },
  uriInfo: {
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
  },
  uriText: {
    fontSize: RFValue(13),
    color: colors.white,
    fontFamily: fonts.light,
    width: 200,
    overflow: "visible",
  },
});

export { styles };
