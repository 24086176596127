import { MaterialCommunityIcons } from "@expo/vector-icons";
import { companyDefaultTheme } from "../../../../assets/theme/companyColors";
import { getColor } from "../../../styles/colors";
import { styles } from "./styles";
import { useCompany } from "../../../hooks/useCompany";

interface CardWithStatusIconProps {
  icon: IconProps;
}

export interface IconProps {
  color: "warn" | "danger" | "green";
  type: "clock" | "live";
}

const CardWithStatusIcon = (props: CardWithStatusIconProps) => {
  const { icon } = props;
  const { company } = useCompany();
  const colors = getColor({
    company: company?.primaryColor ? company : companyDefaultTheme,
  });

  const colorsSet = {
    danger: colors.redCancel,
    warn: colors.textYellow,
    green: colors.textGreen,
  };

  const iconsSet = {
    clock: (
      <MaterialCommunityIcons
        name="progress-clock"
        style={[
          styles.icon,
          {
            color: colorsSet[icon?.color || "warn"],
          },
        ]}
      />
    ),
    live: (
      <MaterialCommunityIcons
        name="antenna"
        style={[
          styles.icon,
          {
            color: colorsSet[icon?.color || "warn"],
          },
        ]}
      />
    ),
  };

  return <>{iconsSet[icon.type]}</>;
};

export { CardWithStatusIcon };
