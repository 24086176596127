import { StyleSheet } from "react-native";

import { RFValue } from "react-native-responsive-fontsize";

import { getColor } from "../../../styles/colors";
import { fonts } from "../../../styles/fonts";

import { companyDefaultTheme } from "../../../../assets/theme/companyColors";

const colors = getColor({ company: companyDefaultTheme });

const styles = StyleSheet.create({
  background: {
    backgroundColor: colors.transparentBlack,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalWrapper: {
    width: "auto",
    height: "auto",
    minWidth: 300,
    backgroundColor: colors.white,
    borderRadius: 20,
    padding: 10,
  },
  title: {
    textAlign: "center",
    fontFamily: fonts.bold,
    fontSize: RFValue(20),
    marginBottom: 15,
    color: colors.text,
  },
  description: {
    textAlign: "center",
    fontSize: RFValue(15),
    fontFamily: fonts.light,
    marginBottom: 20,
  },
  closeWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  closeIcon: {
    fontSize: RFValue(24),
    color: colors.grey,
  },
  buttonsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  button: {
    height: 30,
    width: 100,
    justifyContent: "center",
    alignItems: "center",
    padding: 5,
    borderRadius: 50,
  },
  buttonText: {
    fontFamily: fonts.medium,
    fontSize: RFValue(15),
  },
});

export default styles;
