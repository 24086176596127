import { ReactNode } from "react";
import { TouchableOpacity } from "react-native";
import { companyDefaultTheme } from "../../../../assets/theme/companyColors";
import { getColor } from "../../../styles/colors";
import { styles } from "./styles";
import { useCompany } from "../../../hooks/useCompany";

interface CardWithStatusRootProps {
  children: ReactNode;
  onPress: () => void;
}

const CardWithStatusRoot = (props: CardWithStatusRootProps) => {
  const { children, onPress } = props;
  const { company } = useCompany();
  const colors = getColor({
    company: company?.primaryColor ? company : companyDefaultTheme,
  });

  return (
    <TouchableOpacity
      style={[styles.cardWrapper, { borderBottomColor: colors.primaryColor }]}
      activeOpacity={0.7}
      onPress={onPress}
      key={Math.random()}
    >
      {children}
    </TouchableOpacity>
  );
};

export { CardWithStatusRoot };
