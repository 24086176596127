import { StyleSheet } from "react-native";

import { RFValue } from "react-native-responsive-fontsize";

import { getColor } from "../../styles/colors";
import { fonts } from "../../styles/fonts";

import { companyDefaultTheme } from "../../../assets/theme/companyColors";

const colors = getColor({ company: companyDefaultTheme });

const styles = StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  scrollView: {
    display: "flex",
    width: "100%",
  },
  contentWrapper: {
    marginTop: 47,
  },

  label: {
    fontFamily: fonts.bold,
    fontSize: RFValue(14),
    color: colors.text,
  },

  formWrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: 26,

    width: "100%",
  },
  input: {
    width: "100%",
    height: 59,
    backgroundColor: colors.white,
    borderRadius: 20,
    marginBottom: 6,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 18,
    paddingRight: 18,
  },
  textHelpers: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 26,
  },
  commonHelperText: {
    fontSize: RFValue(12),
    color: colors.text,
    fontFamily: fonts.light,
    marginRight: 3,
  },
  linkingHelperText: {
    fontSize: RFValue(12),
    color: colors.primaryColor,
    fontFamily: fonts.bold,
    textDecorationLine: "underline",
  },
  errorText: {
    fontSize: RFValue(13),
    fontFamily: fonts.light,
    color: colors.textRed,
    textAlign: "center",
    marginBottom: 10,
  },
});

export { styles };
