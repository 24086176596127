import { StatusBar, Text, TouchableOpacity, View } from "react-native";

import { AntDesign, FontAwesome5 } from "@expo/vector-icons";
import { useState } from "react";
import SafeAreaView from "react-native-safe-area-view";
import WebView from "react-native-webview";
import { companyDefaultTheme } from "../../../assets/theme/companyColors";
import { useCompany } from "../../hooks/useCompany";
import { getColor } from "../../styles/colors";
import { BasicLoading } from "../Loadings/BasicLoading";
import { styles } from "./styles";

interface WebViewComponentProps {
  setShowWebView: React.Dispatch<React.SetStateAction<boolean>>;
  uri: string;
}

const WebViewComponent = (props: WebViewComponentProps) => {
  const { setShowWebView, uri } = props;
  const { company } = useCompany();
  const [uriText, setUriText] = useState<string>("");

  const colors = getColor({ company: company ? company : companyDefaultTheme });

  return (
    <SafeAreaView style={styles.container} forceInset={{ top: "always" }}>
      <StatusBar backgroundColor={colors.primaryColor} translucent={true} />
      <View style={[styles.header, { backgroundColor: colors.primaryColor }]}>
        <View style={styles.uriInfo}>
          <FontAwesome5 name="globe-americas" style={styles.globeIcon} />
          <Text style={styles.uriText} numberOfLines={1}>
            {uriText}
          </Text>
        </View>
        <TouchableOpacity
          style={styles.closeButton}
          activeOpacity={0.7}
          onPress={() => {
            setShowWebView(false);
          }}
        >
          <AntDesign name="close" style={styles.closeIcon} />
        </TouchableOpacity>
      </View>
      <WebView
        style={{ flex: 1 }}
        source={{ uri }}
        onError={(error) => {
          setUriText("Serviço indisponível");
        }}
        renderLoading={() => <BasicLoading />}
        autoManageStatusBarEnabled={true}
      />
    </SafeAreaView>
  );
};

export { WebViewComponent };
