import { Dispatch, ReactNode, SetStateAction } from "react";
import {
  KeyboardAvoidingView,
  Modal,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import { AntDesign } from "@expo/vector-icons";

import { companyDefaultTheme } from "../../../../assets/theme/companyColors";
import { useCompany } from "../../../hooks/useCompany";
import { getColor } from "../../../styles/colors";
import styles from "./styles";

interface ConfirmationModalProps {
  title: string;
  description?: string;
  cancelButton?: {
    text: string;
    onClick: (() => Promise<void>) | (() => void);
  };
  confirmButton: {
    color: "blue" | "red" | "grey" | "tertiary";
    text: string;
    onClick: (() => Promise<void>) | (() => void);
  };
  isModalOpen: boolean;
  children?: ReactNode;
  dontShowCancelButton?: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

export default function ConfirmationModal(props: ConfirmationModalProps) {
  const {
    cancelButton,
    confirmButton,
    isModalOpen,
    setIsModalOpen,
    title,
    description,
    dontShowCancelButton,
    children,
  } = props;

  const { company } = useCompany();

  const colors = getColor({
    company: company?.primaryColor ? company : companyDefaultTheme,
  });
  const buttonBackground = {
    red: colors?.redCancel,
    blue: colors?.mainBlue,
    grey: colors?.grey,
    tertiary: colors.tertiaryColor,
  };

  return (
    <Modal transparent visible={isModalOpen} animationType="fade">
      <KeyboardAvoidingView
        style={[StyleSheet.absoluteFill, styles.background]}
      >
        <View style={styles.modalWrapper}>
          <View style={styles.closeWrapper}>
            <AntDesign
              name="close"
              style={styles.closeIcon}
              onPress={() => {
                setIsModalOpen(false);
              }}
            />
          </View>
          <Text style={styles.title}>{title}</Text>

          {description && <Text style={styles.description}>{description}</Text>}
          {children}
          <View style={styles.buttonsWrapper}>
            {dontShowCancelButton ? (
              <></>
            ) : (
              <TouchableOpacity
                style={[styles.button, { backgroundColor: colors.grey }]}
              >
                <Text
                  style={[styles.buttonText, { color: colors.white }]}
                  onPress={() => cancelButton?.onClick()}
                >
                  {cancelButton?.text}
                </Text>
              </TouchableOpacity>
            )}
            <TouchableOpacity
              style={[
                styles.button,
                { backgroundColor: buttonBackground[confirmButton?.color] },
              ]}
              onPress={async () => await confirmButton.onClick()}
            >
              <Text style={[styles.buttonText, { color: colors.white }]}>
                {confirmButton.text}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </KeyboardAvoidingView>
    </Modal>
  );
}
