import { StyleSheet } from "react-native";

import { RFValue } from "react-native-responsive-fontsize";

import { getColor } from "../../../styles/colors";
import { fonts } from "../../../styles/fonts";

import { companyDefaultTheme } from "../../../../assets/theme/companyColors";

const colors = getColor({ company: companyDefaultTheme });

const styles = StyleSheet.create({
  cardWrapper: {
    display: "flex",
    backgroundColor: colors.white,
    minWidth: "90%",
    height: "auto",
    marginTop: 15,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 20,
    borderBottomWidth: 3,
    borderBottomColor: colors.primaryColor,
  },
  icon: {
    display: "flex",
    fontSize: 24,
    alignContent: "flex-end",
    alignItems: "flex-end",
  },
  statusWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  statusTextWrapper: {
    flexDirection: "row",
  },
  statusTextCompliment: {
    fontFamily: fonts.bold,
    color: colors.primaryColor,
    fontSize: RFValue(16),
    marginRight: 5,
  },
  cardStatus: {
    fontFamily: fonts.medium,
    fontSize: RFValue(16),
  },
  cardText: {
    fontFamily: fonts.bold,
    fontSize: RFValue(14),
    color: colors.text,
    marginTop: 7,
  },
  subTitle: {
    fontSize: RFValue(12),
    fontFamily: fonts.light,
    marginTop: 12,
  },
  joinRoomButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.mainBlue,
    width: 200,
    height: "auto",
    margin: "auto",
    alignSelf: "center",
    borderRadius: 50,
    padding: 15,
    marginTop: 15,
  },
  jointBtnText: {
    color: colors.white,
    textAlign: "center",
    fontSize: RFValue(12),
    fontFamily: fonts.bold,
  },
  specialtyName: {
    fontSize: RFValue(13),
    color: colors.text,
    fontFamily: fonts.bold,
    fontStyle: "italic",
  },
  flagText: {
    fontSize: RFValue(13),
    color: colors.white,
    fontFamily: fonts.bold,
  },
  flagWrapper: {
    padding: 5,
    borderRadius: 50,
    marginLeft: 10,
  },
  compliment: {
    fontSize: RFValue(12),
    color: colors.text,
    fontFamily: fonts.medium,
    marginTop: 2,
  },
});

export { styles };
