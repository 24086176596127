import { StyleSheet } from "react-native";
import { RFPercentage } from "react-native-responsive-fontsize";

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  lottieView: {
    width: RFPercentage(10),
    height: RFPercentage(10),
  },
  gif: {
    width: RFPercentage(10),
    height: RFPercentage(10),
  },
});

export { styles };
