import { FontAwesome5 } from "@expo/vector-icons";
import { Dimensions, Text, TouchableOpacity, View } from "react-native";
import { companyDefaultTheme } from "../../../../assets/theme/companyColors";
import { useCompany } from "../../../hooks/useCompany";
import { getColor } from "../../../styles/colors";
import { styles } from "./styles";

interface CardWithIconProps {
  title: string;
  description: string;
  icon: string;
  onPress: () => void;
}

const CardWithIcon = (props: CardWithIconProps) => {
  const { icon, title, description, onPress } = props;
  const windowWidth = Dimensions.get("window").width;

  const { company } = useCompany();

  const colors = getColor({
    company: company.primaryColor ? company : companyDefaultTheme,
  });

  return (
    <TouchableOpacity
      style={[styles.cardWrapper]}
      activeOpacity={0.7}
      onPress={onPress}
    >
      <View style={styles.textWrapper}>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.description}>{description}</Text>
      </View>

      <FontAwesome5
        name={icon}
        size={44}
        color={colors.tertiaryColor}
        style={styles.icon}
      />
    </TouchableOpacity>
  );
};

export { CardWithIcon };
