import { ScheduleOnDemandDoc24Props } from "../../../models/api/doc24/scheduleOnDemandDoc24Props";

import axios from "axios";

async function scheduleOnDemandDoc24(
  props: ScheduleOnDemandDoc24Props,
  accessKey: string
) {
  try {
    const response = await axios.post(
      `${process.env.GLEEBEM_API}/instant-appointment/create`,
      props,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: accessKey,
        },
      }
    );

    return response;
  } catch (error: any) {
    console.error("[ERROR] [scheduleOnDemandDoc24.ts]", error);

    return {
      message:
        "Não foi possível agendar atendimento instantâneo. Por favor, tente mais tarde.",
      error,
    };
  }
}

export { scheduleOnDemandDoc24 };
