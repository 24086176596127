import { updateUserApi } from "../../services/api/user/updateUserApi";

import { UserProps } from "../../models/userProps";
import { Providers } from "../../models/gleebem.core";

interface UpdateNotificationTokenControllerProps {
  user: UserProps;
  pushToken: string;
}

async function updateNotificationTokenController(
  props: UpdateNotificationTokenControllerProps
) {
  const { user, pushToken } = props;

  const updatedNotificationTokens: string[] = user?.notificationTokens
    ? user.notificationTokens
    : ([] as string[]);
  const tokenAlreadyExists =
    updatedNotificationTokens?.indexOf(pushToken) !== -1;

  if (!pushToken || !user?.id || tokenAlreadyExists) {
    return;
  }

  updatedNotificationTokens.push(pushToken);

  let userData: UserProps = {
    ...user,
    notificationTokens: updatedNotificationTokens,
  };

  if (user.provider === Providers.DOC24) {
    userData.identificationType = 9;
    userData.identificationValue = user?.cpf;
  }

  await updateUserApi({
    id: user?.id,
    userData,
  });
}

export { updateNotificationTokenController };
