const config = {
  API_KEY: "AIzaSyAjSBqmnJYukMvKNELcub32lGlaT6ETJ6Y",
  AUTH_DOMAIN: "eu-sou-gleebem.firebaseapp.com",
  PROJECT_ID: "eu-sou-gleebem",
  STORAGE_BUCKET: "eu-sou-gleebem.appspot.com",
  MESSAGING_SENDER_ID: "444154651323",
  APP_ID: "1:444154651323:web:d7ef3c16edd366585cff9e",
  MEASUREMENT_ID: "G-SFD7BE0F4",
  QUEUE_ID: "sbYqeisvuKJHFGJKG5Cy",
  ACCESS_KEY: "FGu_QNSmy8dAnT8ykwP",
  DR_TIS_API: "https://vitalmedhomologacao.drtis.com.br",
  EXPO_PROJECT_ID: "64321a20-28b9-4b2b-8603-26581a3165ed",
  VALIDATION_ACCESS_KEY: "QgzG9tkp5NBk-LpEjIA",
  SECRET_KEY: "SlXaqY_0W-B67z7Eg3m1vBNH12OtZtBnxeo0kcho",
  PUBLIC_VAPID:
    "BLPOX95C_WL7TYpdqNWh80w2QHb0ZxDmTiWXIRJIL9Y9fNPMasE9Ljc6m1OdT9IiujbrkF1hSmLANaDDVAj4qGs",
  ENVIRONMENT: "PRODUCTION",
};

export { config };
