enum PreRegistrationFormActionKind {
  UPDATE_NAME = "UPDATE_NAME",
  UPDATE_LAST_NAME = "UPDATE_LAST_NAME",
  UPDATE_CPF = "UPDATE_CPF",
  UPDATE_EMAIL = "UPDATE_EMAIL",
  UPDATE_PHONE = "UPDATE_PHONE",
  UPDATE_GENDER = "UPDATE_GENDER",
  UPDATE_BIRTHDATE = "UPDATE_BIRTHDATE",
  UPDATE_REGISTRATION = "UPDATE_REGISTRATION",
  CLEAR_DATA = "CLEAR_DATA",
  SET_FULL_DATA = "SET_FULL_DATA",
}

export interface PreRegistrationFormAction {
  type: PreRegistrationFormActionKind;
  name?: string;
  lastName?: string;
  cpf?: string;
  email?: string;
  phone?: string;
  gender?: "Masculino" | "Feminino" | "";
  birthdate?: string;
  registration?: string;
}

export interface PreRegistrationFormState {
  name: string;
  lastName: string;
  cpf: string;
  email: string;
  phone: string;
  gender: "Masculino" | "Feminino" | "";
  birthdate: string;
  registration: string;
}

const preRegistrationFormInitialState: PreRegistrationFormState = {
  name: "",
  lastName: "",
  cpf: "",
  email: "",
  phone: "",
  gender: "",
  birthdate: "",
  registration: "",
};

function preRegistrationFormReducer(
  state: PreRegistrationFormState,
  action: PreRegistrationFormAction
): PreRegistrationFormState {
  switch (action.type) {
    case "UPDATE_NAME":
      return {
        ...state,
        name: action.name || "",
      };
    case "UPDATE_LAST_NAME":
      return {
        ...state,
        lastName: action.lastName || "",
      };
    case "UPDATE_CPF":
      return {
        ...state,
        cpf: action.cpf || "",
      };
    case "UPDATE_EMAIL":
      return {
        ...state,
        email: action.email || "",
      };
    case "UPDATE_PHONE":
      return {
        ...state,
        phone: action.phone || "",
      };
    case "UPDATE_GENDER":
      return {
        ...state,
        gender: action.gender || "",
      };
    case "UPDATE_BIRTHDATE":
      return {
        ...state,
        birthdate: action.birthdate || "",
      };
    case "UPDATE_REGISTRATION":
      return {
        ...state,
        registration: action.registration || "",
      };
    case "CLEAR_DATA":
      return preRegistrationFormInitialState;
    case "SET_FULL_DATA":
      return {
        ...state,
        birthdate: action?.birthdate || "",
        cpf: action?.cpf || "",
        email: action?.email || "",
        gender: action?.gender || "",
        name: action?.name || "",
        lastName: action?.lastName || "",
        phone: action?.phone || "",
        registration: action?.registration || "",
      };

    default:
      return state;
  }
}

export {
  preRegistrationFormReducer,
  preRegistrationFormInitialState,
  PreRegistrationFormActionKind,
};
