import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  imageWrapper: {
    height: 35,
    width: 35,
    borderRadius: 50,
  },
  image: {
    width: 35,
    height: 35,
    borderRadius: 50,
  },
});

export { styles };
