import React, { ReactNode } from "react";
import {
  Dimensions,
  KeyboardAvoidingView,
  Modal,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";

import { AntDesign } from "@expo/vector-icons";
import styles from "./styles";

interface ModalBottomSheetProps {
  children: ReactNode;
  onRequestClose: () => void;
  visible: boolean;
  header?: ReactNode;
  title?: string;
  height?: number;
}

export default function ModalBottomSheet(props: ModalBottomSheetProps) {
  const { children, height, onRequestClose, title, header, visible } = props;

  const defaultHeight = (Dimensions.get("window").height * 282) / 760;

  return (
    <Modal
      animationType="slide"
      transparent
      visible={visible}
      onRequestClose={() => onRequestClose()}
    >
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={[StyleSheet.absoluteFill, styles.keyboardAvoidingView]}
      >
        <View
          style={[styles.modalWrapper, { height: height || defaultHeight }]}
        >
          <View style={styles.header}>
            <Pressable
              onPress={onRequestClose}
              style={styles.closeButton}
              hitSlop={{ bottom: 10, left: 5, right: 5, top: 5 }}
            >
              <AntDesign name="close" size={30} style={styles.closeIcon} />
            </Pressable>
            {title && <Text style={styles.title}>{title}</Text>}
            {header}
          </View>
          <View style={styles.content}>{children}</View>
        </View>
      </KeyboardAvoidingView>
    </Modal>
  );
}
