const imagesSourcesProd = {
  profileImage:
    "https://firebasestorage.googleapis.com/v0/b/eu-sou-gleebem.appspot.com/o/assets%2Fprofile.jpg?alt=media&token=835da958-a07d-4f80-b0bb-c8aa39b8e5c9",
};

const imagesSourcesDev = {
  profileImage:
    "https://firebasestorage.googleapis.com/v0/b/eu-sou-gleebem-dev.appspot.com/o/assets%2Fprofile.jpg?alt=media&token=675e47a0-cd31-42f9-91b0-dbcb8a4d9813",
};

function getAssetsSources() {
  // return process?.env?.ENVIRONMENT === "prod"
  //   ? imagesSourcesProd
  //   : imagesSourcesDev;

  return imagesSourcesDev;
}

export { getAssetsSources };
